import React, { useEffect } from 'react'

import './RideList_sctruct.scss'

function RideList_sctruct() {


  return (
    <div className="ridelistsctruct">
        <section className="ridelistsctruct_sctucture">
            <div className="ridelistsctruct_sctucture-id element">
                №
            </div>
            <div className="ridelistsctruct_sctucture-number element">
                Номер ТС
            </div>
            <div className="ridelistsctruct_sctucture-entry element">
                Въезд
            </div>
            <div className="ridelistsctruct_sctucture-exit element">
                Выезд
            </div>
            <div className="ridelistsctruct_sctucture-duration element">
                Длительность
            </div>
            <div className="ridelistsctruct_sctucture-weight element">
                Вес, кг
            </div>
            <div className="ridelistsctruct_sctucture-photo element">
                Фото
            </div>
        </section>
    </div>
  )
}

export {RideList_sctruct}
