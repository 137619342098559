import React from 'react'
import {Notification} from '../../ui/notification/Notification'
import {ExitButton} from '../../ui/exit_button/ExitButton'

import './Cabinet.scss'

function Cabinet() {
  return (
    <div className="cabinet">
        <section className="cabinet__row">
            <div className="cabinet__row-avatar">
                <img src={require('../../ui/images/avatar.png')} alt="#" />
            </div>
            <article className="cabinet__row-info">
                <div className="cabinet__row-info-base">
                    <p className="cabinet__row-info-base-text"> Анастасия Великая </p>
                    <div className="cabinet__row-info-base-images">
                        <img className="cabinet__row-info-base-images-default" src={require('../../ui/images/Profile_button.png')} alt="#" />
                        <img className="cabinet__row-info-base-images-hover" src={require('../../ui/images/Profile_button_hover.png')} alt="#" />
                    </div>
                </div>
                <div className="cabinet__row-info-time">
                    45 минут назад
                </div>
            </article>
            <div className="cabinet__row-actions">
                <Notification className="cabinet__row-actions-notify action_button"/>
                <ExitButton className="cabinet__row-actions-logout action_button"/>
            </div>
        </section>
    </div>
  )
}

export {Cabinet}
