import React from 'react'
import { Link } from 'react-router-dom'

import './ExitButton.scss'

function ExitButton() {
  return (
    <>
    <Link to='/'>
      <div className="exitbutton">
          <img className="exitbutton__image" src={require('../images/Logout_icon.png')} alt="#" />
      </div>
    </Link>
    </>
    
  )
}

export {ExitButton}