import React from 'react'
import { Link } from 'react-router-dom'

import './AuthForm.scss'

function AuthForm() {
  return (
    <div className="authform">
        <div className="authform__login">
            <p className="authform__login-title">Логин</p>
            <input className="authform__login-input" type="text" placeholder='Почта'/>
        </div>
        <div className="authform__password">
            <p className="authform__login-title">Пароль</p>
            <input className="authform__login-input" type="password" placeholder='**********'/>
            <p className="authform__login-help">Забыли?</p>
        </div>
        <Link to='/transits'>
            <button className="authform__enter">
                Войти
            </button>
        </Link>
        
    </div>
  )
}

export {AuthForm}
