import React from 'react'
import { Routes_card } from '../routes_card/Routes_card'
import { Exported_card } from '../exported_card/Exported_card'
import { Day_card } from '../day_card/Day_card'
import { Relation_card } from '../relation_card/Relation_card'
import { Companies_card } from '../companies_card/Companies_card'

import './Analytics_cards.scss'

function Analytics_cards() {

  let today = new Date()

  const companies = [
    {
      color: '#913E98',
      text: 'ОАО «УгольНефтеТорг» — 65,3 % (204 565 тонн)',
      name: 'ОАО «УгольНефтеТорг»',
      transits: '12 259',
      exported: '204 565',
      day: '2,0'
    },
    {
      color: '#478ECC',
      text: 'ООО «УралСельМаш» — 12,8 % (86 481 тонна)',
      name: 'ООО «УралСельМаш»',
      transits: '35 598',
      exported: '86 481',
      day: '1,8'
    },
    {
      color: '#1998A7',
      text: 'ОАО «Гром» — 8,4 % (31 214 тонны)',
      name: 'ОАО «Гром»',
      transits: '24 025',
      exported: '31 214',
      day: '2,2'
    },
    {
      color: '#F58020',
      text: 'ЗАО «Крутая гора» — 6,1 % (22 204 тонны)',
      name: 'ЗАО «Крутая гора»',
      transits: '36 874',
      exported: '22 204',
      day: '2,8'
    },
    {
      color: '#FF7373',
      text: 'ОАО «Купол» — 3,6 % (15 102 тонны)',
      name: 'ОАО «Купол»',
      transits: '12 033',
      exported: '15 102',
      day: '3,1'
    },
  ]

  return (
    <div className="analyticscards">
        <p className="analyticscards_title">Статистика за сутки ({String(today.getDate()).padStart(2, '0')}.{String(today.getMonth() + 1).padStart(2, '0') }.{today.getFullYear()})</p>

        <div className="analyticscards__row">
          <Routes_card percents={'72,30%'} count={'10 845'} plan={'15 000'}/>
          <Exported_card percents={'64,12%'} count={'385 000'} plan={'600 000'}/>
          <Day_card count={'2,2'}/>
          <Relation_card companies={companies}/>
          <Companies_card companies={companies}/>
        </div>

    </div>
  )
}

export {Analytics_cards}
