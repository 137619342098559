import React from 'react'

import './Analytics_filter.scss'

function Analytics_filter() {
  return (
    <div className="analyticsfilter">
        <section className="analyticsfilter__row">
            
            <select id="selectPit" className="analyticsfilter__row-pit selector">
                <option value="number">Карьер</option>
                <option value="number">1</option>
                <option value="number">2</option>
                <option value="number">3</option>
                <option value="number">4</option>
            </select>
            <select id="selectPeriod" className="analyticsfilter__row-period selector">
                <option value="number">Период</option>
                <option value="number">1</option>
                <option value="number">2</option>
                <option value="number">3</option>
                <option value="number">4</option>
            </select>
            <select id="selectCompany" className="analyticsfilter__row-company selector">
                <option value="number">Компания</option>
                <option value="number">1</option>
                <option value="number">2</option>
                <option value="number">3</option>
                <option value="number">4</option>
            </select>
        </section>
    </div>
  )
}

export {Analytics_filter}
