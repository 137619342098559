import React from 'react'
import { Link } from 'react-router-dom'

import './AuthForm.scss'

function RegForm() {
  return (
    <div className="regform">
        <div className="regform__login">
            <p className="regform__login-title">Имя</p>
            <input className="regform__login-input" type="text" placeholder='Например: Иван Петров'/>
        </div>
        <div className="regform__password">
            <p className="regform__login-title">Пароль</p>
            <input className="regform__login-input" type="password" placeholder='**********'/>
        </div>
        <div className="regform__mail">
            <p className="regform__mail-title">Почта</p>
            <input className="regform__mail-input" type="text" placeholder='example@mail.ru'/>
        </div>
        <div className="regform__check">
            <input className="regform__check-input" type="checkbox" placeholder='**********'/>
            <p className="regform__check-title">Показывать почту на сайте</p>
            
        </div>
        <Link to='/transits'>
            <button className="regform__enter">
                Зарегистрироваться
            </button>
        </Link>
    </div>
  )
}

export {RegForm}
