import React from 'react'
import { Cabinet } from '../../components/cabinet/Cabinet'
import { Analytics_title } from '../../components/analytics_title/Analytics_title'
import { Analytics_cards } from '../../components/analytics_cards/Analytics_cards'

import './Analytics.scss'

function Analytics({adaptive, setAdaptive}) {
  return (
    <div className='analytics'>
        <Cabinet/>
        <Analytics_title adaptive={adaptive} setAdaptive={setAdaptive}/>
        <Analytics_cards/>
    </div>
  )
}

export {Analytics}
