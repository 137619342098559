import React from 'react'

import './Burger.scss'

function Burger() {
  return (
    <div className="burger">
        
    </div>
  )
}

export {Burger}