import React, { useEffect, useState } from 'react'
import { Cabinet } from '../../components/cabinet/Cabinet'
import { RideList_title } from '../../components/rideList_title/RideList_title'
import { RideList_sctruct } from '../../components/rideList_structure/RideList_sctruct'
import { RideList_list } from '../../components/rideList_list/RideList_list'
import { GetRideData } from '../../requests/GetRideData'

import './RideList.scss'


function RideList({adaptive, setAdaptive}) {

  const [rideData, setRideData] = useState(['none'])
  const [loading, setLoading] = useState(false)

  const setData = async () => {
    //await setRideData(GetRideData())
  }

  useEffect(() => {
    try {
      setLoading(true)
      let data = fetch('https://careers.cgeng.pro/result.json', {
        method: "GET", 
        mode: "no-cors", 
        credentials: 'same-origin',
        headers: {'Access-Control-Allow-Origin': '*'}
        }).then(response => response.json()).then(response => {
          setRideData(response)
        })
        console.log(data, 'transport data');
      setLoading(false)

      console.log(rideData, 'from RideList')
    } catch (error) {
      throw new Error(`ERROR ${error}`)
    }
  }, [])

  return (
    <div className="ridelist">
      <Cabinet/>
      <RideList_title adaptive={adaptive} setAdaptive={setAdaptive}/>
      <RideList_sctruct/>
      {
        rideData.length > 2 ? <RideList_list rideData={rideData} loading={loading} setAdaptive={setAdaptive}/> : <h1>Loading data</h1>
      }
      
    </div>
  )
}

export {RideList}