import React, { useEffect, useState } from 'react'
import { RideList_element } from '../rideList_element/RideList_element'
import {Paginator} from '../paginator/Paginator'

import './RideList_list.scss'

function RideList_list(props) {

    const [dataLength, setDataLength] = useState(0)

    const {rideData, loading, setAdaptive} = props

    const [dataPerPage] = useState(30)
    
    const [currentPage, setCurrentPage] = useState(1)

    const lastElementIndex = currentPage * dataPerPage
    const firstElementIndex = lastElementIndex - dataPerPage

    let currentData = []

    if (rideData){
       currentData = rideData.slice(firstElementIndex, lastElementIndex)
    } else{
      console.log('no-data')
    }
    

    const Paginate = pageNumber => setCurrentPage(pageNumber)

    useEffect(() => {
      console.log(rideData, 'from RideList__list')
      if(rideData){
        setDataLength(rideData.length)
      } else{
        console.log('Data not found')
      }
    })

  return (
    <div className="ridelistlist" onClick={() => {setAdaptive(false)}}>
        {
            currentData.map((elem) => <RideList_element 
                key={elem.id}
                id={elem.id} 
                number={elem.vehicle} 
                enter={elem.Entry_date} 
                enter_time={elem.Entry_time} 
                exit={elem.Exit_date}
                exit_time={elem.Exit_time}
                duration={elem.duration}
                weight={elem.weight}
                photo={elem.link_to_photo}
                loading = {loading}
            />)
        } 
        <Paginator dataPerPage={dataPerPage} totalData={dataLength} paginate={Paginate} currentPage={currentPage}/>

    </div>
  )
}

export {RideList_list}
