import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker'

import './Routes_card.scss'

function Routes_card({percents, count, plan}) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
      );

    const options = {
        responsive: true,
        scales: {
            x: {
                display: false,
                grid: {
                    display: false
                },
            },
            y: {
                display: false,
                grid: {
                    display: false
                },
            }
        },
        plugins: {
          legend: {
            display: false
          },

        },
      };
    
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels,
        datasets: [
          {
            fill: true,
            data: labels.map(() => faker.datatype.number({ min: 100, max: 200 })),
            borderColor: 'rgb(255, 180, 84)',
            backgroundColor: 'rgba(255, 180, 84, 1.0)',
          },
        ],
      };
    
  return (
    <div className="routescard">
        <section className="routescard__row">
            <p className="routescard__row-title">Сделано рейсов:</p>
            <p className="routescard__row-percents">{percents}</p>
        </section>
        <p className="routescard__count">
            {count}
        </p>
        <p className="routescard__plan-title">
            План:
        </p>
        <p className="routescard__plan-count">
            {plan}
        </p>
        <div className="routescard__chart">
            <Line options={options} data={data} />
        </div>
    </div>
  )
}

export {Routes_card}
