import React from 'react'

import './Companies_card.scss'

function Companies_card({companies}) {
  return (
    <div className="companiescard">
        <div className="companiescard__row">
            <div className="companiescard__row-company">
                <p className="companiescard__row-company-title">Компания</p>
                {
                    companies.map((company) => (
                        <p className="companiescard__row-company-name" style={{color: company.color}}>{company.name}</p>
                    ))
                }  
            </div>
            <div className="companiescard__row-transits">
                <p className="companiescard__row-transits-title">Количество рейсов</p>
                {
                    companies.map((company) => (
                        <p className="companiescard__row-transits-name">{company.transits}</p>
                    ))
                }  
            </div>
            <div className="companiescard__row-exported">
                <p className="companiescard__row-exported-title">Вывезено (тонн)</p>
                {
                    companies.map((company) => (
                        <p className="companiescard__row-exported-name">{company.exported}</p>
                    ))
                }  
            </div>
            <div className="companiescard__row-day">
                <p className="companiescard__row-day-title">Среднее кол-во рейсов</p>
                {
                    companies.map((company) => (
                        <p className="companiescard__row-day-name">{company.day}</p>
                    ))
                }  
            </div>
        </div>
    </div>
  )
}

export {Companies_card}
