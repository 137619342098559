import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import './Day_card.scss'

function Day_card({count}) {

  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    responsive: true,
    scales: {
        x: {
            display: false,
            grid: {
                display: false
            },
        },
        y: {
            display: false,
            grid: {
                display: false
            },
        }
    },
    plugins: {
      legend: {
        display: false
      },

    },
  };

  const data = {
    labels: ['today', 'yesterday'],
    datasets: [
      {
        label: '# of Votes',
        data: [85, 15],
        backgroundColor: [
          'rgba(255, 180, 84, 1.0)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(255, 180, 84, 1.0)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
    
  return (
    <div className="daycard">
        <p className="daycard__row-title">
            Среднее количество рейсов машины в сутки
        </p>
        <p className="daycard__count">
            {count}
        </p>
        <p className="daycard__plan-title">
            по сравнению 
            <p>со вчерашним днем</p>
        </p>
        <div className="daycard__chart">
          <Pie data={data} options={options}/>
        </div>
    </div>
  )
}

export {Day_card}
